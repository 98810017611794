var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "iframes",
    staticClass: "iframes",
    attrs: {
      allowfullscreen: "true",
      src: _vm.iframeWinPaht,
      frameborder: "0",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <iframe
    allowfullscreen='true' 
    class="iframes"
    :src="iframeWinPaht"
    frameborder="0"
    ref="iframes"
  />
    <!-- <iframe
    allowfullscreen='true'
    class="iframes"
    src="http://localhost:8080/ppt/#/edit"
    frameborder="0"
    ref="iframes"
  /> -->
</template>
<script>
export default {
  data() {
    return {
      loading: null,
      iframeWin: {},
      iframeWinPaht: process.env.VUE_APP_PPT_IDE + "edit",
      pptData: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem('pptToken'),
        platform: localStorage.getItem("platform"), //id 1	merchant	可达鸭商户平台 id 2	tms	可达鸭平台
        currentTaskId: "",
        currentTaskName: "",
        resourceId: "",
        courseId: "",
        courseName: "",
        unitId: "",
        unitName: "",
        sort: 0,
        taskList: [], //编辑时可为空
      },
    };
  },
  mounted() {
    // this.loading = this.$loading({
    //   lock: true,
    //   text: '加载中...',
    //   spinner: 'el-icon-loading',
    //   background: 'rgba(0, 0, 0, 0.7)'
    // });
    this.iframeWin = this.$refs.iframes.contentWindow;
    window.addEventListener("message", this.handleMessage, true);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage, true);
  },
  created() {
    const currentCourseInfo = JSON.parse(
      localStorage.getItem("currentCourseInfo")
    );
    const currentTaskInfo = JSON.parse(localStorage.getItem("currentTaskInfo"));
    
    this.pptData = {
      ...this.pptData,
      currentTaskId: currentTaskInfo.id,
      currentTaskName: currentTaskInfo.title,
      resourceId: currentTaskInfo.sourceId,
      courseId: currentCourseInfo.id,
      courseName: currentCourseInfo.title,
      unitId: currentTaskInfo.courseUnitId,
      unitName: currentTaskInfo.courseUnitTitle,
      sort: currentTaskInfo.sort,
    };
  },
  methods: {
    sendMessage(data) {
      if (this.iframeWin.attachEvent) {
        this.iframeWin.attachEvent("onload", () => {
          this.iframeWin.postMessage(data, "*");
        });
      } else {
        this.iframeWin.onload = () => {
          this.iframeWin.postMessage(data, "*");
        };
      }
    },
    handleMessage(e) {
      switch (e.data.type) {
        case "setUseWareId":
          console.log(e.data)
          this.$emit("setUseWareId",e.data.data.id);
          break;
        case "getData":
          // this.loading.close();
          this.iframeWin.postMessage(
            { data: this.pptData, type: "initData" },
            "*"
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.iframes {
  width: calc(100vw);
  height: calc(100vh);
  overflow: hidden;
}
</style>